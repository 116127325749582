import clsx from 'clsx';
import React, { useState } from 'react';

import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonRoot: {
    borderRadius: 0,
    background: 'transparent',
    color: '#000',
    padding: '8px 16px 8px 24px',
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      lineHeight: 1,
      fontWeight: '400',
    },
    '& .MuiButton-endIcon': {
      margin: 0,
    },
    '&:hover': {
      background: 'transparent',
      color: '#000',
      '& .MuiButton-label': {},
    },
  },
  indicatorRoot: {
    position: 'absolute',
    right: 0,
  },
  indicatorBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    border: '1px solid #464646',
    margin: 4,
    transition: 'background 0.5s',
  },
  active: {
    background: '#464646',
  },
}));

const CompleteLookButton = (props) => {
  const classes = useStyles();
  const { active, onClick } = props;

  return (
    <Grid container alignItems="center" style={{ padding: '16px 0' }}>
      <Grid item xs className={classes.buttonContainer}>
        <Button
          variant="text"
          onClick={onClick}
          disableRipple
          classes={{ root: classes.buttonRoot }}
          endIcon={!active ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        >
          {!active ? 'Complete Look' : 'Top'}
        </Button>
        <Box className={classes.indicatorRoot}>
          <div className={classes.indicatorBox}>
            <span className={clsx(classes.dot, !active && classes.active)}></span>
            <span className={clsx(classes.dot, active && classes.active)}></span>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CompleteLookButton;
