import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(0.5),
  },
  sizeGuideRow: {
    fontSize: 16,
    color: '#000',
    '& div': {
      margin: '8px 0',
    },
    '& div:last-child': {
      textAlign: 'end',
    },
  },
  fontBold: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  fontFade: {
    color: '#0009',
  },
  priceGrid: {
    width: 75,
    textAlign: 'end',
  },
  nameSpan: {
    display: 'inline-block',
    minWidth: 50,
  },
}));

const ItemGroup = (props) => {
  const { itemGroup } = props;

  const classes = useStyles();

  return (
    <Grid className={classes.root} item container>
      <Grid item container className={classes.sizeGuideRow}>
        <Grid item xs>
          <span className={classes.fontBold}>{itemGroup.name}</span>
        </Grid>
        <Grid item className={classes.priceGrid}>
          <span className={classes.fontBold}>Price</span>
        </Grid>
        <Grid item className={classes.priceGrid}>
          <span className={classes.fontBold}>RRP</span>
        </Grid>
      </Grid>
      {itemGroup.items &&
        itemGroup.items.map((item, index) => (
          <Grid key={index} item container className={classes.sizeGuideRow}>
            <Grid item xs>
              <span className={classes.nameSpan}>{item.name}</span>
              <span>{item.description}</span>
            </Grid>
            <Grid item className={classes.priceGrid}>
              <span>${item.price.toFixed(2)}</span>
            </Grid>
            <Grid item className={classes.priceGrid}>
              <span>${item.priceRRP.toFixed(2)}</span>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default ItemGroup;
