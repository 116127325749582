import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';

import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useStoreContext from 'src/hooks/useStoreContext';
import locationService from 'src/apis/services/location.service';
import mediaService from 'src/apis/services/media.service';
import productService from 'src/apis/services/product.service';
import resourceManager from 'src/services/resource.manager';
import { BorderLinearProgress } from 'src/styled-components/BorderLinearProgress';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    margin: '0px 4px',
    width: 48,
    background: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 100,
    overflowX: 'hidden',
    transition: `width 0.5s`,
    '&:hover': {
      cursor: 'pointer',
      width: '125px',
      '& span': {
        opacity: 1,
      },
    },
  },
  buttonBox: {
    color: '#000',
    padding: '8px 16px',
    display: 'inline-flex',
    alignItems: 'center',
    transformOrigin: 'left',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
  buttonText: {
    color: '#000',
    marginLeft: 8,
    lineHeight: 1,
    fontSize: 12,
    textTransform: 'uppercase',
    transition: `opacity 0.5s`,
    opacity: 0,
  },
  progressbar: {
    position: 'relative',
    textAlign: 'center',
    transition: `opacity 0.5s`,
  },
  progressText: {
    position: 'absolute',
    transform: 'translate(-40%,-100%)',
    fontSize: '.5em',
    color: '#fff',
  },
  focusButton: {
    width: '125px',
    '& span': {
      opacity: 1,
    },
  },
}));

const DownloadButton = () => {
  const classes = useStyles();
  const [download, setDownload] = useState(false);
  const { storeId } = useStoreContext();
  const locations = useMemo(() => locationService.getLocations(), [storeId]);
  const medias = useMemo(() => mediaService.getMedias(), [storeId]);
  const products = useMemo(() => productService.getProducts(), [storeId]);
  const [urlsList, setUrlsList] = useState([]);
  const [urlsListCompleted, setUrlsListCompleted] = useState([]);
  // const [cacheCompleted, setCacheCompleted] = useState(false);
  const progressRef = useRef();

  useEffect(() => {
    async function getUrls() {
      const tmpMediaUrls = medias.map((item) => item.source);
      const tmpLocationUrls = locations.map((item) => item.img);
      const tmpProductUrls = products.map((item) => item.image);

      let tmpUrls = [...tmpMediaUrls, ...tmpLocationUrls, ...tmpProductUrls];
      const urls = await resourceManager.checkUrlsCached(storeId, tmpUrls);
      setUrlsList(urls);
    }
    if (storeId) {
      getUrls();
    }
  }, [storeId]);

  const handleButtonClick = async () => {
    const urlsNotCache = await resourceManager.checkUrlsCached(storeId, urlsList);
    setUrlsList(urlsNotCache);
    setDownload(true);
    const completed = await resourceManager.cacheAllResource(storeId, urlsNotCache);
    if (completed) {
      // setTimeout(() => {
      //   setCacheCompleted(true);
      // }, 2250);
      setTimeout(() => {
        setDownload(false);
      }, 3000);
    }
  };

  useEffect(() => {
    return () => {
      // setCacheCompleted(false);
      setUrlsListCompleted([]);
    };
  }, [download]);

  useEffect(() => {
    resourceManager.subscribeEvents(handleUpdateUrlList);
    return () => {
      resourceManager.unsubscribeEvents();
    };
  }, []);

  const handleUpdateUrlList = (url) => {
    setUrlsListCompleted((prvState) => [...prvState, url]);
  };

  const [actived, setActived] = useState(false);
  const breakpoint = useBreakpoint();

  const checkSmallScreen = (breakpoint) => {
    switch (breakpoint) {
      case 'xl':
        return false;
      case 'lg':
        return false;
      case 'md':
        return true;
      case 'sm':
        return true;
      case 'xs':
        return true;
      default:
        return false;
    }
  };
  useLayoutEffect(() => {
    if (checkSmallScreen(breakpoint)) {
      setActived(true);
    } else {
      setActived(false);
    }
  }, [breakpoint]);

  return (
    <div
      className={clsx(classes.buttonWrapper, actived || download ? classes.focusButton : '')}
      // onMouseEnter={() => setHovered(true)}
      // onMouseLeave={() => setHovered(false)}
      onClick={handleButtonClick}
      // style={{ maxWidth: hovered || download ? 124 : 48 }}
    >
      <div className={classes.buttonBox}>
        <SaveAltOutlinedIcon />
        {download ? (
          <div className={classes.progressbar} ref={progressRef}>
            <BorderLinearProgress
              variant="determinate"
              value={(urlsListCompleted?.length / urlsList?.length) * 100}
            />
            <span className={classes.progressText}>
              {urlsListCompleted?.length !== urlsList?.length
                ? `${urlsListCompleted?.length}/${urlsList?.length}`
                : 'completed'}
            </span>
          </div>
        ) : (
          <span className={classes.buttonText}>{`Download`}</span>
        )}
      </div>
    </div>
  );
};

export default DownloadButton;
