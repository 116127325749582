import React, { useEffect, useState } from 'react';

import { Box, Divider, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import productService from 'src/apis/services/product.service';
import tagGroupsService from 'src/apis/services/tag-groups.service';
import tagService from 'src/apis/services/tag.service';
import useWindowLandscape from 'src/hooks/useWindowLandscape';

import ProductThumbnail from './ProductThumbnail';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
  },
  // title: {
  //   boxSizing: 'border-box',

  //   /* Auto layout */

  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'flex-start',
  //   padding: '24px 0px',
  //   gap: 8,

  //   borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
  //   marginBottom: 16,
  // },
  closeIconButton: {
    ackground: 'transparent',
    padding: 0,
    border: 'none',
    color: '#000',
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginRight: 12,
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: 24,
    textTransform: 'uppercase',
  },
  scrollBox: {
    position: 'relative',
    width: 'calc(100% + 8px)',
    marginBottom: 16,
    overflowY: 'auto',
    paddingRight: 6,
    marginLeft: -8,
    '&::-webkit-scrollbar': {
      width: 6,
      // display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(213, 213, 213)',
      borderRadius: '100px',
    },
  },
  productThumbnail: {
    padding: 8,
  },
}));

export function CompleteLookContainer(props) {
  const { tagId, close, handleScrollView } = props;
  const classes = useStyle();
  const [tagGroup, setTagGroup] = useState({});
  const [products, setProducts] = useState();

  useEffect(() => {
    if (tagId) {
      let tagGroup = tagGroupsService.getTagGroupByTagId(tagId);
      // console.log('tagGroup', tagGroup);
      setTagGroup(tagGroup);
    } else {
      setTagGroup(null);
    }
  }, [tagId]);

  useEffect(() => {
    if (tagGroup && tagGroup.tags) {
      let products = tagGroup.tags
        .map((tagId) => {
          let tag = tagService.getTagById(tagId);
          // console.log(tag);

          let product = productService.getProductById(tag.productId);
          let tmpProduct = { ...product, tagId: tagId, tagPosition: tag.position };
          return tmpProduct;
        })
        .filter((product) => product != null);

      // console.log('products', products);
      setProducts(products);
    } else {
      setProducts([]);
    }
  }, [tagGroup]);

  const isLandscape = useWindowLandscape();

  return (
    // <Box className={classes.root}>
    <Grid item container style={{ height: '100%' }} direction="column" wrap="nowrap">
      <Grid item container alignItems="center">
        <Grid item xs>
          <span className={classes.title}>{`Complete`}</span>
          <span className={classes.subTitle}>{`look`}</span>
        </Grid>
        <Grid item>
          <IconButton disableRipple className={classes.closeIconButton} onClick={close}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box my={2}>
            <Divider />
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.scrollBox}>
        {products &&
          products.map((product) => (
            <Grid
              key={`thumbnail${product.id}`}
              item
              xs={12}
              sm={isLandscape ? 12 : 6}
              md={9}
              lg={6}
              xl={6}
              className={classes.productThumbnail}
            >
              <ProductThumbnail
                product={product}
                handleScrollView={handleScrollView}
              ></ProductThumbnail>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}
