import React, { useEffect, useState } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

// import useCameraTarget from 'src/hooks/useCameraTarget';
import useTagsStatus from 'src/hooks/useTagsStatus';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 8,
    background: '#F7F7F7',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image: (props) => ({
    width: 135,
    height: 135,
    // flex: 'none',
    // order: 0,
    // flexGrow: 0,
    backgroundImage: `url(${props.imageUrl})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
  }),
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 8,
    // width: 'calc( 100% - 135px )',
  },
  title: {
    fontSize: 14,
    lineHeight: 1.1,
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
  },
  price: {
    padding: '4px 8px',
    textAlign: 'center',
    background: '#000000',
    fontSize: 14,
    color: '#FFFFFF',
    lineHeight: 1,
  },
}));

export default function ProductThumbnail(props) {
  const { product, handleScrollView } = props;
  const classes = useStyle({ imageUrl: product.image });
  const { setCurrentActiveTag } = useTagsStatus();
  const [viewModel, setViewModel] = useState({
    name: '',
    price: null,
  });

  // const { setCameraTargetPosition } = useCameraTarget();

  useEffect(() => {
    if (product) {
      let name = `${product.seriesName} ${product.name}`;
      let firstItem = product.itemGroups[0]?.items[0];
      let price = firstItem?.price;
      let priceRRP = firstItem?.priceRRP;
      let priceLabel = `$${price}/$${priceRRP}`;

      setViewModel({
        name: name,
        price: priceLabel,
      });
    }
  }, [product]);

  const handleClick = () => {
    // setCameraTargetPosition(product.tagPosition);
    setCurrentActiveTag(product.tagId);
    handleScrollView();
  };

  return (
    <Box className={classes.root} onClick={handleClick}>
      <Box className={classes.image}></Box>
      <Box className={classes.details}>
        <Typography className={classes.title} variant="subtitle2" gutterBottom>
          {viewModel.name}
        </Typography>
        <Typography className={classes.price}>{viewModel.price}</Typography>
      </Box>
    </Box>
  );
}
