import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';

import { makeStyles } from '@material-ui/core';
import PauseOutlinedIcon from '@material-ui/icons/PauseOutlined';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';

import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useStoreContext from 'src/hooks/useStoreContext';
import storesService from 'src/apis/services/stores.service';
import VolumeSlider from 'src/styled-components/VolumeSlider';

import WelcomeDialog from './WelcomeDialog';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    margin: '0px 4px',
    maxWidth: 48,
    background: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 100,
    overflowX: 'hidden',
    transition: `max-width 0.5s`,
    '&:hover': {
      cursor: 'pointer',
      maxWidth: '90px',
      '& span': {
        opacity: 1,
      },
    },
  },
  buttonBox: {
    color: '#000',
    padding: '8px 16px',
    display: 'inline-flex',
    alignItems: 'center',
    transformOrigin: 'left',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
  buttonText: {
    color: '#000',
    marginLeft: 8,
    lineHeight: 1,
    fontSize: 12,
    textTransform: 'uppercase',
    transition: `opacity 0.5s`,
    opacity: 0,
  },
  dialogPaper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%',
  },
  backDrop: {
    backdropFilter: 'blur(8px)',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
  },
  dialogTitle: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
  },
  logo: {
    maxHeight: 32,
    maxWidth: 350,
    width: '100%',
    height: '100%',
    padding: 8,
  },
  dialogContentText: {
    color: '#fff',
    fontWeight: '300',
  },
  buttonRoot: {
    borderRadius: 0,
    background: '#000',
    color: '#fff',
    padding: '8px 16px 8px 24px',
    marginTop: 32,
    width: 147,
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      lineHeight: 1,
      transition: `width 0.5s`,
      width: '85%',
      fontWeight: '300',
    },
    '& .MuiButton-endIcon': {
      margin: 0,
    },
    '&:hover': {
      background: '#000',
      color: '#fff',
      '& .MuiButton-label': {
        width: '100%',
      },
    },
  },
  focusButton: {
    maxWidth: '90px',
    '& span': {
      opacity: 1,
    },
  },
}));

const BackgroundMusic = ({ setAnimateLogo }) => {
  const classes = useStyles();
  const [url, setUrl] = useState();
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.4);
  const [openModal, setOpenModal] = useState(true);
  const backgroundMedia = {
    url: [
      '/assets/audios/Surfaces-SundayBest.mp3',
      '/assets/audios/NewRadicals-YouGetWhatYouGive.mp3',
      '/assets/audios/KungsVsCookinOn3Burners-ThisGirl.mp3',
      '/assets/audios/ChrisValentine-HighLife.mp3',
      '/assets/audios/VanceJoy-MissingPiece.mp3',
      '/assets/audios/EAST-LifeGoesOn.mp3',
    ],
    playing: false,
  };
  const { storeId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(), [storeId]);

  useEffect(() => {
    if (!openModal) {
      let backgroundMediaUrl = '';
      if (backgroundMedia && backgroundMedia.url !== undefined) {
        let randomNumber = random1Number();
        let mediaLength = backgroundMedia.url.length;
        let mediaIndex = randomNumber % mediaLength;
        backgroundMediaUrl = backgroundMedia.url[mediaIndex];
      }
      setUrl(backgroundMediaUrl);
    }
  }, [openModal]);

  const handlePlayerReady = () => {
    console.log('handlePlayerReady');
  };
  const handlePlayerStart = () => {
    console.log('handlePlayerStart');
  };
  const handlePlayerPlay = () => {
    console.log('handlePlayerPlay');
  };
  const handlePlayerError = (e) => {
    console.log('handlePlayerError: ', e);
    setPlaying(false);
  };
  const handlePlayerEnded = () => {
    console.log('handlePlayerEnded');
    nextAudio();
  };
  const handleVolumeChange = (newValue) => {
    setVolume(newValue);
  };

  const nextAudio = () => {
    let prevIndex = backgroundMedia.url.indexOf(url);
    if (prevIndex + 1 < backgroundMedia.url.length) {
      setUrl(backgroundMedia.url[prevIndex + 1]);
    } else {
      setUrl(backgroundMedia.url[0]);
    }
  };

  const random1Number = () => {
    let number = Date.now();
    let lastDigitStr = String(number).slice(-1);
    const lastDigitNumber = Number(lastDigitStr);
    return lastDigitNumber;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setPlaying(true);
  };

  const breakpoint = useBreakpoint();
  const [actived, setActived] = useState(false);

  const checkSmallScreen = (breakpoint) => {
    switch (breakpoint) {
      case 'xl':
        return false;
      case 'lg':
        return false;
      case 'md':
        return true;
      case 'sm':
        return true;
      case 'xs':
        return true;
      default:
        return false;
    }
  };

  useLayoutEffect(() => {
    if (checkSmallScreen(breakpoint)) {
      setActived(true);
    } else {
      setActived(false);
    }
  }, [breakpoint]);

  return (
    <>
      <div
        className={clsx(classes.buttonWrapper, actived ? classes.focusButton : '')}
        onClick={() => setPlaying(!playing)}
      >
        <ReactPlayer
          className={classes.reactPlayer}
          url={url}
          playing={playing}
          // loop={true}
          volume={volume}
          width="auto"
          height="auto"
          onReady={handlePlayerReady}
          onStart={handlePlayerStart}
          onPlay={handlePlayerPlay}
          onError={handlePlayerError}
          onEnded={handlePlayerEnded}
        />
        <div className={classes.buttonBox}>
          {playing ? <PauseOutlinedIcon /> : <PlayArrowOutlinedIcon />}
          <span className={classes.buttonText}>{playing ? `Pause` : `Play`}</span>
        </div>
      </div>
      {volume >= 0 && <VolumeSlider volume={volume} handleVolumeChange={handleVolumeChange} />}
      <WelcomeDialog
        storeData={storeData}
        open={openModal}
        close={handleCloseModal}
        setAnimateLogo={setAnimateLogo}
      />
    </>
  );
};

export default BackgroundMusic;
