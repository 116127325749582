import DataStorage from 'src/apis/data/DataStorage';

class TagGroupsRepository {
  constructor() {}

  getTagGroups = () => {
    return DataStorage.tagGroups;
  };
}

export default new TagGroupsRepository();
