import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid, Slide, makeStyles } from '@material-ui/core';

import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useCameraTarget from 'src/hooks/useCameraTarget';
import useTagsStatus from 'src/hooks/useTagsStatus';
import useWindowLandscape from 'src/hooks/useWindowLandscape';
import pointerManager from 'src/services/pointer.manager';
import productService from 'src/apis/services/product.service';
import tagGroupsService from 'src/apis/services/tag-groups.service';
import tagService from 'src/apis/services/tag.service';

import { CompleteLookContainer } from './CompleteLook/CompleteLookContainer';
import CompleteLookButton from './ProductDetails/CompleteLookButton';
import ProductDetails from './ProductDetails/ProductDetails';

const useStyles = makeStyles((theme) => ({
  landscapeRoot: (props) => ({
    width: props.width,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: 0,
    zIndex: 101,
    padding: '40px 40px 0 40px',
    color: '#000',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'width 0.5s',
  }),
  portraitRoot: {
    // width: 585,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 0,
    zIndex: 101,
    padding: '40px 40px 0 40px',
    color: '#000',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  closeIconButton: {
    background: 'transparent',
    padding: 0,
    border: 'none',
    color: '#000',
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  productSeries: {
    fontSize: 24,
    fontWeight: 700,
    marginRight: 12,
  },
  productName: {
    fontSize: 24,
  },
  sizeGuideRow: {
    fontSize: 20,
    color: '#000',
    '& div': {
      margin: '8px 0',
    },
    '& div:last-child': {
      textAlign: 'end',
    },
  },
  fontBold: {
    fontWeight: 700,
  },
  fontFade: {
    color: '#0009',
  },
  contentContainer: {
    maxHeight: '100%',
    // height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
}));

const ProductDetailsModal = (props) => {
  const { open, close } = props;
  const ref = useRef();
  const completeLookRef = useRef();
  const detailRef = useRef();
  const {
    currentTagId,
    setCurrentActiveTag,
    clearActiveTag,
    setCurrentActiveTags,
  } = useTagsStatus();
  const [tagGroup, setTagGroup] = useState();
  const [product, setProduct] = useState();

  const tagData = useMemo(() => tagService.getTagById(currentTagId), [currentTagId]);

  useEffect(() => {
    pointerManager.onClickWithoutMove(handleClose);
    return () => {
      pointerManager.offClickWithoutMove(handleClose);
    };
  }, []);

  useEffect(() => {
    if (tagData) {
      let tagGroup = tagGroupsService.getTagGroupByTagId(tagData.id);
      setTagGroup(tagGroup ? tagGroup : null);
      setIsScrollView(false);
    } else {
      setTagGroup(null);
    }
  }, [tagData]);

  const handleClose = () => {
    close();
    clearActiveTag();
  };

  //  useOnClickOutside(ref, handleClose);

  const [isScrollView, setIsScrollView] = useState(false);

  const handleScrollView = () => {
    setIsScrollView((prev) => !prev);
  };

  const scrollViewUp = () => {
    detailRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setCurrentActiveTag(tagData.id);
  };

  const scrollViewDown = () => {
    completeLookRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setCurrentActiveTags(tagGroup.tags);
  };

  useEffect(() => {
    if (tagGroup && completeLookRef?.current && detailRef?.current) {
      isScrollView ? scrollViewDown() : scrollViewUp();
    }
  }, [isScrollView]);

  useEffect(() => {
    return () => setIsScrollView(false);
  }, [open]);

  useEffect(() => {
    if (open && tagData && tagData.productId) {
      let product = productService.getProductById(tagData.productId);
      setProduct(product);
    } else {
      setProduct(null);
    }
  }, [open, tagData]);

  const isLandscape = useWindowLandscape();
  const breakpoint = useBreakpoint();

  const getWidth = (breakpoint) => {
    switch (breakpoint) {
      case 'xl':
        return 530;
      case 'lg':
        return 520;
      case 'md':
        return 440;
      case 'sm':
        return 300;
      case 'xs':
        return 250;
      default:
        return 500;
    }
  };

  const classes = useStyles({ width: getWidth(breakpoint) });

  return (
    <Slide
      direction={isLandscape ? 'left' : 'up'}
      in={open}
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <Box
        className={isLandscape ? classes.landscapeRoot : classes.portraitRoot}
        height={isLandscape ? 'auto' : '45%'}
        ref={ref}
      >
        <Grid container className={classes.contentContainer}>
          {/* //////////// */}
          <Grid item container style={{ height: '100%' }} ref={detailRef}>
            {product && <ProductDetails product={product} handleClose={handleClose} />}
          </Grid>
          {/* //////////////// */}

          {/* ////////// */}
          <Grid item container style={{ height: '100%' }} ref={completeLookRef}>
            <CompleteLookContainer
              tagId={tagData?.id}
              close={handleClose}
              handleScrollView={handleScrollView}
            />
          </Grid>
          {/* /////////////// */}
        </Grid>
        {tagGroup ? (
          <CompleteLookButton active={isScrollView} onClick={handleScrollView} />
        ) : (
          <span style={{ paddingTop: 40 }}></span>
        )}
      </Box>
    </Slide>
  );
};

export default ProductDetailsModal;
