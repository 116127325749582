import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import DomeManager from '../services/Dome.manager';

const useStyles = makeStyles({
  root: {
    width: 1600,
    position: 'absolute',
    bottom: 16,
    left: 150,
    zIndex: 100,
  },
});

export default function SliderRotation() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    DomeManager.updateRotationDegrees(newValue);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography id="continuous-slider" gutterBottom>
        Rotation degrees: {value},
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs>
          <Slider
            value={value}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
            step={0.001}
            min={-360}
            max={360}
            valueLabelDisplay="auto"
          />
        </Grid>
      </Grid>
    </div>
  );
}
