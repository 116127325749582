import React, { useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useWindowLandscape from 'src/hooks/useWindowLandscape';

// import BackgroundMedia from './BackgroundMedia';
import BackgroundMusic from './BackgroundMusic';
import DownloadButton from './DownloadButton';
import EntranceButton from './EntranceButton';
import LogoHeader from './LogoHeader';
import NavigationMenu from './NavigationMenu';
import SliderRotation from './SliderRotation';
// import SliderRotation from './SliderRotation';
import SplashScreen from './SplashScreen';

// import UpdateResourceButton from './UpdateResourceButton';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'black',
    position: 'relative',
  },
  bottom: {
    position: 'absolute',
    bottom: 40,
    left: 40,
    display: 'flex',
    alignItems: 'center',
    transformOrigin: 'left',
  },
}));

export default function Layout(props) {
  const classes = useStyles();
  const breakpoint = useBreakpoint();
  const isLandscape = useWindowLandscape();
  const [animateLogo, setAnimateLogo] = useState(false);

  return (
    <div className={classes.container} style={{ width: '100%', height: '100%' }}>
      {props.children}
      <LogoHeader animateLogo={animateLogo} />
      <NavigationMenu />
      {/* <BackgroundMedia /> */}
      {/* <SliderRotation /> */}
      <SplashScreen />
      {/* <UpdateResourceButton /> */}
      <Box
        className={classes.bottom}
        style={{
          transform: `scale(${breakpoint == 'xs' ? 0.7 : 1})`,
          left: breakpoint == 'xs' ? 20 : 40,
          bottom: breakpoint == 'xs' ? 20 : 40,
        }}
      >
        <EntranceButton />
        <DownloadButton />
        <BackgroundMusic setAnimateLogo={setAnimateLogo} />
      </Box>
    </div>
  );
}
