import React, { memo, useMemo } from 'react';

import tagService from '../apis/services/tag.service';
import TagInstance from './Tag/TagInstance';

const Tags = (props) => {
  const tagMockData = useMemo(() => tagService.getTags(), []);

  return (
    <>
      {tagMockData.map((tag, index) => {
        return <TagInstance key={`tag${tag.id}${index}`} tag={tag} />;
      })}
    </>
  );
};

export default memo(Tags);
