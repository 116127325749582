import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Physics } from '@react-three/cannon';
import { Canvas } from '@react-three/fiber';

import locationService from 'src/apis/services/location.service';
import storesService from 'src/apis/services/stores.service';
import { CameraContext } from 'src/contexts/CameraContext';
import { CurrentPositionContext } from 'src/contexts/CurrentPositionContext';
import { useCaches } from 'src/hooks/useCaches';
import useStoreContext from 'src/hooks/useStoreContext';
import useTagsStatus from 'src/hooks/useTagsStatus';
import CustomControls from 'src/model-components/CustomControls';
import KeyboardControls from 'src/model-components/KeyboardControls';
import Locations from 'src/model-components/Locations';
import Media from 'src/model-components/Media';
import Space from 'src/model-components/Space';
import Tags from 'src/model-components/Tags';
import resourceManager from 'src/services/resource.manager';

import ProductDetailsModal from './ProductDetailsModal';

const MainView = (props) => {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { storeId } = useStoreContext();
  const [, setCameraContext] = useContext(CameraContext);
  const [, setCurrentPositionContext] = useContext(CurrentPositionContext);
  const storeData = useMemo(() => storesService.getStore(), [storeId]);
  const locationMockData = useMemo(() => locationService.getLocations(), [storeId]);
  const [currentPoint, setCurrentPoint] = useState();
  const [openModal, setOpenModal] = useState(false);
  const currentLocationIdRef = useRef();
  const cacheUrl = useCaches(storeId, storeData.modelSource);

  const { currentTagId } = useTagsStatus();

  const performBackgroundCachingResources = async (storeId) => {
    let locations = locationService.getLocations();
    let splashLocationImages = locations.map((x) => x.img);
    await resourceManager.cacheAllResource(storeId, splashLocationImages);
  };

  useEffect(() => {
    document.title = `${storeData.name.toUpperCase()}`;
    setCameraContext(storeData.cameraRotation);
    if (storeData.favIcon) {
      let link = document.querySelector("link[rel~='icon']");
      link.href = storeData.favIcon;
    }

    const locationStart = locationMockData.find((x) => x.id === locationId);
    if ((storeId && !locationId) || (storeId && !locationStart)) {
      navigate(`/${storeData.storeFront}`);
    }

    if (storeId) {
      performBackgroundCachingResources(storeId);
    }
  }, [storeId]);

  useEffect(() => {
    let idParam = locationId;
    if (idParam) {
      currentLocationIdRef.current = idParam;
      const location = locationMockData.find((x) => x.id === idParam);
      if (location) {
        setCurrentPoint(location);
      } else {
        navigate(`/${storeData.storeFront}`);
      }
    } else {
      navigate(`/${storeData.storeFront}`);
    }
  }, [locationId]);

  useEffect(() => {
    if (currentPoint?.position) {
      setCurrentPositionContext({ ...currentPoint.position });
    }
  }, [currentPoint]);

  const handleClickPoint = useCallback(
    (point) => {
      if (currentLocationIdRef.current != point.id) {
        navigate(`/${point.id}`);
      }
    },
    [currentPoint]
  );

  const handleRightMouseClickWorldLocation = ({ x, y, z }, face) => {
    console.log('position', { x, y, z }, '\nface', face);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    // console.log('currentTagId', currentTagId);
    if (currentTagId) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [currentTagId]);

  return (
    <>
      {cacheUrl && (
        <>
          <Canvas camera={{ fov: 70 }}>
            <Suspense fallback={null}>
              <ambientLight intensity={0.95} />
              <color attach="background" args={['#00001e']} />
              <Physics gravity={[0, -10, 0]}>
                {storeData && <CustomControls cameraRotation={storeData.cameraRotation} />}
                <Locations
                  locations={locationMockData}
                  handleClickPoint={handleClickPoint}
                  currentPoint={currentPoint}
                />

                <Space
                  url={cacheUrl}
                  modelId={storeData.modelId}
                  handleRightMouseClickWorldLocation={handleRightMouseClickWorldLocation}
                />
                <Tags />
                {currentPoint && (
                  <KeyboardControls
                    currentPosition={currentPoint.position}
                    currentPointId={currentPoint.id}
                  />
                )}
                {storeId && <Media storeId={storeId} />}
              </Physics>
            </Suspense>
          </Canvas>

          {/* <ModalContainer open={openModal} close={handleModalClose} currentTag={currentTag} /> */}
          <ProductDetailsModal open={openModal} close={handleModalClose} />
        </>
      )}
    </>
  );
};

export default MainView;
