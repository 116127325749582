import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

import storesService from 'src/apis/services/stores.service';
import useStoreContext from 'src/hooks/useStoreContext';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 100,
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, 0)',
    top: 40,
    transition: 'opacity 1.75s',
  },
  imageLogo: {
    width: 178,
    height: 32,
  },
}));

const LogoHeader = ({ animateLogo }) => {
  const classes = useStyles();
  const { storeId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(), [storeId]);

  return (
    <div className={classes.root} style={{ opacity: animateLogo ? 1 : 0 }}>
      <img src={storeData?.logo} alt="brand-logo" className={classes.imageLogo} />
    </div>
  );
};

export default LogoHeader;
