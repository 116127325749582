import React, { useEffect, useState } from 'react';

import { Box, Fade, LinearProgress, makeStyles } from '@material-ui/core';

import { useProgress } from '@react-three/drei';

import useStoreContext from 'src/hooks/useStoreContext';

import '../App.css';

const useStyles = makeStyles((theme) => ({
  '@keyframes typing ': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  },
  dotContainer: {
    display: 'inline-block',
    width: 18,
    textAlign: 'left',
  },
  animateDot: {
    display: 'inline-flex',
    overflow: 'hidden',
    fontSize: 24,
    animation: `$typing 1.5s linear infinite`,
  },
  backgroundRoot: {
    width: '100%',
    height: '100%',
    backgroundColor: '#c9c5c1',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1301,
  },
  backdrop: {
    backdropFilter: 'blur(8px)',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    zIndex: 1301,
    alignItems: 'center',
    justifyContent: 'center',
    color: ' #ffffff',
  },
  container: {
    position: 'relative',
    maxWidth: 750,
    width: '100%',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    maxHeight: 32,
    maxWidth: 350,
    width: '100%',
    height: '100%',
    padding: 8,
    marginBottom: 20,
  },
  progressbarRoot: {
    height: 14,
    borderRadius: 10,
    // display: 'block',
    // width: 68,
    marginLeft: 16,
    marginRight: 16,
  },
  progressbarColorPrimary: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    borderRadius: 10,
  },
  progressbarBar: {
    borderRadius: 10,
    backgroundColor: '#fff',
  },
  determinate: {
    borderRadius: 10,
  },
  bar1Determinate: {
    borderRadius: 10,
  },
}));

const SplashScreen = () => {
  const classes = useStyles();
  const { storeId } = useStoreContext();
  const progressState = ['We are setting up the environment for you', 'Almost done', 'Done'];
  const [progressText, setProgressText] = useState(progressState[0]);
  const [progressNumber, setProgressNumber] = useState(0);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const { progress } = useProgress();

  let showLoadingScreenTimeout;
  let progressNumberInterval;

  useEffect(() => {
    if (storeId) {
      setShowLoadingScreen(true);
      setProgressNumber(0);
    }
  }, [storeId]);

  useEffect(() => {
    // console.log('progress', progress);

    const calculateProgressNumber = () => {
      let maxNumber = 0;
      let delay = 600;
      if (progress === 0) {
        maxNumber = 35;
      } else if (progress > 30 && progress < 75) {
        maxNumber = 75;
        delay = 500;
      } else if (progress === 100) {
        maxNumber = 100;
        delay = 25;
      }
      progressNumberInterval = setInterval(() => {
        setProgressNumber((prevProgress) =>
          prevProgress >= maxNumber ? prevProgress + 0 : prevProgress + 1
        );
      }, delay);
    };

    calculateProgressNumber();

    return () => {
      clearInterval(progressNumberInterval);
    };
  }, [progress]);

  useEffect(() => {
    return () => {
      if (!showLoadingScreen) {
        clearTimeout(showLoadingScreenTimeout);
        clearInterval(progressNumberInterval);
      }
    };
  }, [showLoadingScreen]);

  useEffect(() => {
    if (progressNumber === 80) {
      setProgressText(progressState[1]);
    } else if (progressNumber === 100) {
      setProgressText(progressState[2]);
      showLoadingScreenTimeout = setTimeout(() => {
        setShowLoadingScreen(false);
      }, 600);
    }
  }, [progressNumber]);

  const bgImage = '/assets/imgs/linen-house-bg.jpg';

  return (
    <Fade in={showLoadingScreen} timeout={{ enter: 0, exit: 700 }}>
      <div className={classes.backgroundRoot} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={classes.backdrop}>
          <div className={classes.container}>
            <img src={'./assets/imgs/linen-house-logo.svg'} className={classes.logo} />
            <Box width={'100%'} marginTop={6} height={48}>
              <LinearProgress
                classes={{
                  root: classes.progressbarRoot,
                  colorPrimary: classes.progressbarColorPrimary,
                  bar: classes.progressbarBar,
                  determinate: classes.determinate,
                  bar1Determinate: classes.bar1Determinate,
                }}
                variant="determinate"
                value={progressNumber}
              />
            </Box>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default SplashScreen;
