import React from 'react';

import Tag from './Tag';

const TagInstance = (props) => {
  const { tag } = props;

  return (
    <group position={[tag.position.x, tag.position.y, tag.position.z]}>
      <Tag tagData={tag} />
    </group>
  );
};

export default TagInstance;
