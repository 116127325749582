import React, { useEffect, useRef } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: 'blur(8px)',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
  },
  dialogPaper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%',
    overflow: 'unset',
  },
  dialogTitle: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
  },
  logo: {
    maxHeight: 32,
    maxWidth: 350,
    width: '100%',
    height: '100%',
    padding: 8,
    transition: 'transform 0.75s',
  },
  dialogContentText: {
    color: '#fff',
    fontWeight: '300',
  },
  buttonRoot: {
    borderRadius: 0,
    background: '#000',
    color: '#fff',
    padding: '8px 16px 8px 24px',
    marginTop: 32,
    width: 147,
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      lineHeight: 1,
      transition: `width 0.5s`,
      width: '85%',
      fontWeight: '300',
    },
    '& .MuiButton-endIcon': {
      margin: 0,
    },
    '&:hover': {
      background: '#000',
      color: '#fff',
      '& .MuiButton-label': {
        width: '100%',
      },
    },
  },
}));

const WelcomeDialog = (props) => {
  const { open, storeData, close, setAnimateLogo } = props;
  const classes = useStyles();
  const logoRef = useRef();

  useEffect(() => {
    if (!open && logoRef?.current) {
      logoRef.current.style.transform = 'translate(0, -372px) scale(0.5)';
      setAnimateLogo(true);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialogPaper }}
      disableEscapeKeyDown={true}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      transitionDuration={500}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography
        classes={{ root: classes.dialogTitle }}
      >
        <img src={storeData?.logo} className={classes.logo} ref={logoRef} />
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Typography variant="subtitle1" className={classes.dialogContentText}>
          {/* Welcome to the Cotton On Experience. */}
          {`${storeData?.welcomeText}`}
        </Typography>
        <Button
          variant="contained"
          onClick={close}
          classes={{ root: classes.buttonRoot }}
          endIcon={<NavigateNextIcon />}
        >
          Continue
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeDialog;
