import { myEmitter } from '../seed-works/myEmitter';

class DomeManager {
  constructor() {}

  updateRotationDegrees = (degrees) => {
    myEmitter.emit(DomeEventName.UpdateRotationDegrees, degrees);
  };

  subscribeEvents = (onDegreesUpdate) => {
    if (onDegreesUpdate) {
      myEmitter.on(DomeEventName.UpdateRotationDegrees, onDegreesUpdate);
    }
  };

  unsubscribeEvents = () => {
    myEmitter.removeAllListeners(DomeEventName.UpdateRotationDegrees);
  };
}

const DomeEventName = {
  UpdateRotationDegrees: 'UpdateDomeRotationDegrees',
};

export default new DomeManager();
